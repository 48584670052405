<template>
<CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="5">
                    <h4>KYC Sample Image</h4>
                    <!-- <p style="font-size:12px;">Background Image are only available in Pay Now to Moolahgo eWallet and Pay Now to Bank screens</p>   -->
              </CCol>
              <CCol md="5">
                   <CInput label="Search" v-model="filter" placeholder="Type to Search" horizontal />
              </CCol>
              <CCol md="2"> 
                   <b-button variant="primary" to="/kyc/image/create" >New Image</b-button>
              </CCol>             
          </CRow>           
        </CCardHeader>
        <CCardBody>
            <CRow>
                  <CCol col="12" class="text-left">                   
                    <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                  </CCol> 
            </CRow>  
            <CRow>
                  <CCol col="12" class="text-center">                   
                    <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
                  </CCol> 
            </CRow> 

          <div class="table-responsive table">
	        <b-table striped hover  :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter">	        

            <template #cell(index)="{ item }">
                {{ items.indexOf(item) + 1 }}
            </template>

            <template v-slot:cell(createDate)="{ item }"> 
                  {{ item.createDate | dateWithTime }}
            </template> 

            <template v-slot:cell(actions)="{ item }">
              <b-dropdown variant="info" text="Actions" size="md">  
                 <router-link :to='"/kyc/image/edit/" + item.id' tag="b-dropdown-item" > Edit </router-link>                   
                 <router-link :to='"/kyc/image/" + item.id' tag="b-dropdown-item" > Image </router-link>                 
              </b-dropdown>
            </template>
            
	        </b-table>
          </div>

          <b-row>
            <b-col sm="3">
               <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>       
            <b-col sm="9">
               <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
               </b-form-select>
            </b-col>
          </b-row>  

        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>  

<script>
import AccountService from '@/api/AccountService.js';

 export default {
    name: "kyc-sample-image",
    data() {
      return {
        items:[],                  
        fields: [
          { key: 'index',label : '#'},
          { key: 'name',label : 'Name',sortable: true}, 
          { key: 'description',label : 'Description', sortable: true}, 
          { key: 'createDate',label : 'Created Date',sortable: true}, 
          { key: 'actions',label: 'Actions'} 
        ],       
        currentPage: 1,
        perPage: 10,
        filter: null,        
        spinner:false,
        seen:false,
        msg :'',
        color:''
      };
    },
    directives: {
        dateSubstr: {
            update(el) {
                var temp = el.value.toString().split(".");
                el.value =  temp[0].replace("T"," ");
            },
        },        
    },   

    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },
        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)         
        },
        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },
        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        }
    },

    created () {
       this.getKYCSampleImage();     
    },
    methods: { 

      isSuperAdmin(){
        if(localStorage.getItem('sa')==="false"){  
            this.$router.push('/dashboard');
        }
      },

      getKYCSampleImage: function() {
        AccountService.getKYCSampleImage().then(resp => {  //console.log(resp);     
            this.items = resp; 
        }, error => {
          this.spinner = false;
        });
      },

    }   
 }; 

</script>
<style>
    .table{ overflow-y:scroll; }
</style>